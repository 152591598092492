<template>
  <c-list
    :style-type="type"
    :style-pos="pos" 
    v-bind="$attrs"
  >
    <slot />
  </c-list>
</template>

<script>

export default {
  name: 'BaseList',
  props: {
    type: {
      type: String,
      default: 'decimal', // disc or decimal
    },
    pos: {
      type: String,
      default: 'outside', // outside or inside
    },
  },
}
</script>

<style>

</style>