var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-popover', {
    attrs: {
      "placement": "bottom-end"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var onClose = _ref.onClose;
        return [_c('c-popover-trigger', [_c('c-button', {
          attrs: {
            "variant": "ghost",
            "width": ['28px', '35px'],
            "min-width": ['28px', '35px'],
            "height": "35px",
            "margin": "0",
            "padding": "0"
          }
        }, [_c('c-box', {
          attrs: {
            "width": ['20px', '30px'],
            "height": ['20px', '30px']
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-calendar-today.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1)], 1)], 1), _c('c-popover-content', {
          attrs: {
            "z-index": "4"
          }
        }, [_c('c-popover-arrow'), _c('c-popover-close-button'), _c('c-popover-header', {
          attrs: {
            "font-size": "18px",
            "font-weight": "500"
          }
        }, [_vm._v(" Kalender Makan ")]), _c('c-popover-body', {
          attrs: {
            "padding": "0",
            "width": "100%",
            "margin-inline": "auto",
            "text-align": "center"
          }
        }, [_c('v-calendar', {
          attrs: {
            "rows": _vm.rows,
            "columns": _vm.columns,
            "step": _vm.step,
            "attributes": _vm.attributes,
            "min-date": _vm.minDate,
            "max-date": _vm.maxDate,
            "masks": _vm.masks,
            "model-config": _vm.modelConfig,
            "mode": "date",
            "title-position": "left",
            "first-day-of-week": 1,
            "locale": _vm.locale,
            "from-date": _vm.fromDate
          },
          on: {
            "dayclick": function dayclick(el) {
              _vm.onDayClick(el, onClose);
            }
          }
        })], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }