<template>
  <c-list-item
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </c-list-item>
</template>

<script>
export default {
  name: 'BaseListItem',
  props: {
  },
}
</script>

<style>

</style>