var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseList', {
    attrs: {
      "type": _vm.type,
      "pos": "outside",
      "ml": "24px",
      "font-size": ['12px', '16px'],
      "color": "neutral.superDarkGray"
    }
  }, _vm._l(_vm.items, function (item, i) {
    var _item$menus, _item$menus2;

    return _c('BaseListItem', {
      key: i
    }, [(item === null || item === void 0 ? void 0 : item.menuType) === 'multipleEntry' ? _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.menuName) + " "), _c('ListMenuRecommendation', {
      attrs: {
        "type": "disc",
        "items": item === null || item === void 0 ? void 0 : item.menus
      }
    })], 1) : (item === null || item === void 0 ? void 0 : item.menuType) === 'singleEntry' ? _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$menus = item.menus) === null || _item$menus === void 0 ? void 0 : _item$menus.servingSize) + " " + _vm._s(item === null || item === void 0 ? void 0 : (_item$menus2 = item.menus) === null || _item$menus2 === void 0 ? void 0 : _item$menus2.servingSizeUnit) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.menuName) + " ")]) : _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.servingSize) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.servingSizeUnit) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.foodName) + " ")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }