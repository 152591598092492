<template>
  <c-box>
    <c-popover
      v-slot="{ onClose }"
      placement="bottom-end"
    >
      <c-popover-trigger>
        <c-button
          variant="ghost"
          :width="['28px','35px']"
          :min-width="['28px','35px']"
          height="35px"
          margin="0"
          padding="0"
        >
          <c-box
            :width="['20px', '30px']"
            :height="['20px', '30px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-calendar-today.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
        </c-button>
      </c-popover-trigger>
      <c-popover-content 
        z-index="4"
      >
        <c-popover-arrow />
        <c-popover-close-button />
        <c-popover-header
          font-size="18px"
          font-weight="500"
        >
          Kalender Makan
        </c-popover-header>
        <c-popover-body
          padding="0"
          width="100%"
          margin-inline="auto"
          text-align="center"
        >
          <v-calendar
            :rows="rows"
            :columns="columns"
            :step="step"
            :attributes="attributes"
            :min-date="minDate"
            :max-date="maxDate"
            :masks="masks"
            :model-config="modelConfig"
            mode="date"
            title-position="left"
            :first-day-of-week="1"
            :locale="locale"
            :from-date="fromDate"
            @dayclick="(el) => {
              onDayClick(el, onClose)
            }"
          >
            <!-- <template #day-popover="{day, format, masks}">
              <div class="text-xs text-gray-700 dark:text-gray-300">
                Using my own content now
              </div>
            </template> -->
          </v-calendar>
        </c-popover-body>
      </c-popover-content>
    </c-popover>
  </c-box>
</template>

<script>
export default {
  name: 'MealCalendar2',
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    columns: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    masks: {
      type: Object,
      default: () => ({
        input: 'DD-MM-YYYY',
        data: 'YYYY-MM-DD',
      }),
    },
    modelConfig: {
      type: Object,
      default: () => {},
    },
    fromDate: {
      type: [Date, null],
      default: null,
    },
  },
  emits: ['update'],
  data() {
    return {
      locale: {
        id: 'id',
        firstDayOfWeek: 1,
        masks: {
          weekdays: 'WWWW',
        },
      },
    }
  },
  computed: {},
  methods: {
    onDayClick(el, onClose) {
      if (el?.isDisabled === false) {
        if (onClose) onClose()
        this.$emit('update', el)
      }
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CPopper"] {
  max-width: max-content;
}
::v-deep .vc-container {
  border: none;
}
::v-deep .vc-weekday {
  padding-inline: 4px;
  font-weight: 500;
}
::v-deep .vc-pane-layout .vc-pane:not(:last-child) {
  border-bottom: 1px solid #E2E8F0;
}
</style>