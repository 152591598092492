var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-list', _vm._b({
    attrs: {
      "style-type": _vm.type,
      "style-pos": _vm.pos
    }
  }, 'c-list', _vm.$attrs, false), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }