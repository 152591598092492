var render = function () {
  var _vm$item, _vm$item$data$calorie, _vm$item2, _vm$item2$data, _vm$item3, _vm$item4, _vm$item4$data, _vm$item5, _vm$item5$data, _vm$item6, _vm$item6$data;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    staticClass: "client_meal-plan_menu-recommendation_4",
    class: _vm.isTourActive ? 'pointer-events-none' : '',
    attrs: {
      "padding": "16px",
      "border-radius": "8px",
      "background-color": "neutral.white",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "cursor": _vm.isDataMenuSkipped ? 'not-allowed' : 'pointer',
      "_hover": {
        backgroundColor: 'neutral.superLightGray'
      }
    },
    on: {
      "click": _vm.onOpenMenuRecommendation
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "8px",
      "opacity": _vm.isDataMenuSkipped ? '0.5' : '1'
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', '600'],
      "color": "neutral.black"
    }
  }, [_vm._v(" " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.label) + " "), !_vm.isDataMenuSkipped && _vm.isDataMenuExist ? _c('c-text', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" - " + _vm._s((_vm$item$data$calorie = (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$data = _vm$item2.data) === null || _vm$item2$data === void 0 ? void 0 : _vm$item2$data.caloriesAmount) !== null && _vm$item$data$calorie !== void 0 ? _vm$item$data$calorie : 0) + " kkal ")]) : _vm._e()], 1), (_vm$item3 = _vm.item) !== null && _vm$item3 !== void 0 && _vm$item3.data ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "12px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '500']
    }
  }, [_vm._v(" " + _vm._s(_vm.format24Time((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : (_vm$item4$data = _vm$item4.data) === null || _vm$item4$data === void 0 ? void 0 : _vm$item4$data.startTime)) + " - " + _vm._s(_vm.format24Time((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : (_vm$item5$data = _vm$item5.data) === null || _vm$item5$data === void 0 ? void 0 : _vm$item5$data.endTime)) + " ")]), _c('c-box', {
    attrs: {
      "width": ['12px', '14px'],
      "height": ['12px', '14px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(-90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1) : _vm._e()], 1), _vm.isDataMenuExist ? _c('ListMenuRecommendation', {
    attrs: {
      "items": (_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : (_vm$item6$data = _vm$item6.data) === null || _vm$item6$data === void 0 ? void 0 : _vm$item6$data.dataMenu
    }
  }) : _vm.isDataMenuSkipped ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "opacity": "0.5"
    }
  }, [_c('c-box', {
    attrs: {
      "min-height": ['14px', '18px'],
      "min-width": ['14px', '18px'],
      "height": ['14px', '18px'],
      "width": ['14px', '18px'],
      "color": "#1A202C"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-info-2.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px']
    }
  }, [_vm._v(" Jam makan di skip ")])], 1) : _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "min-height": ['14px', '18px'],
      "min-width": ['14px', '18px'],
      "height": ['14px', '18px'],
      "width": ['14px', '18px'],
      "color": "#DA6D06"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-info-2.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "color": "warning.400"
    }
  }, [_vm._v(" Jam makan belum tersedia ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }